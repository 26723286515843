import React, { Component } from 'react'
import { Button, Form, Container, Header, Grid, GridRow, GridColumn, Divider, Card, CardContent, Segment, Menu, MenuItem, Image, Message } from 'semantic-ui-react'
import axios from 'axios';
import './App.css';
import banks from './data/banks';

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      employeeInfo: {
        name: '',
        date: '',
        rate: 0,
        bank: '',
        account: ''
      },
      tasks: [
        {
          summary: '',
          amount: 0,
          hours: 0
        }
      ],
      isSubmitted: false,
      menuFixed: false,
      overlayFixed: false,
    };

    this.currentDate = new Date().toLocaleDateString();
  }

  changeHandler = (e, props) => {
    let name = '';
    let value = '';

    if (props) {
      name = props.name;
      value = props.value;
    } else {
      name = e.target.name;
      value = e.target.value;
    }

    const updatedInfo = {
      ...this.state.employeeInfo,
      [name]: value
    }
    this.setState({
      ...this.state,
      employeeInfo: updatedInfo,
    });
  }

  updateItem = (e, index) => {
    const updatedItems = this.state.tasks;
    const { name, value } = e.target;

    updatedItems[index][name] = value;

    this.setState({
      ...this.state.employeeInfo,
      tasks: updatedItems
    });
  }

  addItem = () => {
    const updatedItems = [
      ...this.state.tasks,
      {
        summary: '',
        hours: 0
      }
    ];

    this.setState({
      ...this.state.employeeInfo,
      tasks: updatedItems
    })
  }

  getGrandTotal = () => {
    const rate = this.state.employeeInfo.rate;
    const total = this.state.tasks.reduce((accumulator, current) => accumulator + (current.hours * rate), 0);

    return total;
  }

  submitHandler = e => {
    e.preventDefault();
    const employeeInfo = this.state.employeeInfo;

    const rows = this.state.tasks.map((item) => {
      return {
        ...this.state.employeeInfo,
        ...item,
        date: this.currentDate,
        rate: employeeInfo.rate,
        hours: item.hours,
        total: employeeInfo.rate * item.hours,
      }
    });

    axios.post('https://sheet.best/api/sheets/de981d46-ea35-4024-a52a-cba2b950c909', rows)
      .then(response => {
        console.log(response);
        this.setState({
          ...this.state,
          isSubmitted: true
        });
      })
  };

  render() {
    const { name, summary, rate, amount, bank, account } = this.state.employeeInfo;
    const items = this.items;

    return (
      <>
        <Menu borderless className='noto-sans-thai'>
          <Container text>
            <MenuItem>
              <Image size='small' src='/logo-codepassion.png' />
            </MenuItem>
            <MenuItem header>เบิกเงิน</MenuItem>
          </Container>
        </Menu>

        <Container text className="container">
          <Header as='h1'>ใบเบิกเงิน</Header>
          {!this.state.isSubmitted && <Form className="form" onSubmit={this.submitHandler}>
            <Card fluid>
              <CardContent>
                <h3>วันที่เบิก</h3>
                <p float="right">{this.currentDate}</p>
              </CardContent>
            </Card>
            <Card fluid>
              <CardContent>
                <h3>ข้อมูลผู้เบิกเงิน</h3>

                <Grid>
                  <GridRow columns={2}>
                    <GridColumn width={12}>
                      <Form.Field>
                        <label>ชื่อ - นามสกุล</label>
                        <input placeholder='นายโค้ด แพสชัน' type="text" name="name" value={name} onChange={this.changeHandler} autoFocus={true} />
                      </Form.Field>
                    </GridColumn>
                    <GridColumn width={4}>
                      <Form.Field >
                        <label>ราคา (หน่วย/ชั่วโมง)</label>
                        <input placeholder='Hour Rate' type="number" name="rate" value={rate} min="0" step="50" onChange={this.changeHandler} />
                      </Form.Field>
                    </GridColumn>
                  </GridRow>
                  <GridRow columns={2}>
                    <GridColumn>
                      <Form.Field>
                        {/* <label>Bank</label> */}
                        {/* <input placeholder='Enter your bank' type="text" name="bank" value={bank} onChange={this.changeHandler} /> */}
                        <Form.Select
                          fluid
                          label='บัญชีรับเงิน'
                          name="bank"
                          options={banks}
                          placeholder='เลือกผู้ให้บริการ'
                          onChange={this.changeHandler}
                        />
                      </Form.Field>
                    </GridColumn>
                    <GridColumn>
                      <Form.Field>
                        <label>เลขที่บัญชี</label>
                        <input placeholder='' type="text" name="account" value={account} onChange={this.changeHandler} />
                      </Form.Field>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </CardContent>
            </Card>

            <Card fluid>
              <CardContent>
                <h3>รายการสิ่งที่เบิก</h3>

                <Grid>
                  {this.state.tasks.map(({ summary, hours }, index) => {
                    return <GridRow columns={4} key={index} stretched={true}>
                      <GridColumn width={12}>
                        <Form.Field>
                          {index === 0 && <label>รายละเอียดงาน หรือสิ่งของ</label>}
                          <textarea placeholder='สรุปสิ่งที่ทำ หรือ ลิงค์ เช่น Jira Ticket, GitHub Pull Request' type="text" name="summary" defaultValue={summary} onChange={(e) => this.updateItem(e, index)} rows="2"></textarea>
                        </Form.Field>
                      </GridColumn>
                      <GridColumn width={4}>
                        <Form.Field>
                          {index === 0 && <label>จำนวน (รายการ/ชั่วโมง)</label>}
                          <input placeholder='Hours' type="number" name="hours" min="0" step="0.5" defaultValue={hours} onChange={(e) => this.updateItem(e, index)} />
                          <div>รวม: {rate * hours} บาท</div>
                        </Form.Field>
                      </GridColumn>
                      {/* <GridColumn width={3}>
                        <Form.Field>
                          <div>{rate * hours}</div>
                        </Form.Field>
                      </GridColumn> */}
                    </GridRow>
                  })}

                  <GridRow>
                    <GridColumn>
                      <Button color="grey" size='small' type='button' onClick={this.addItem}>+ เพิ่มรายการ</Button>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </CardContent>
            </Card>

            <Card fluid>
              <CardContent>
                <Grid columns={2}>
                  <GridRow>
                    <GridColumn verticalAlign='middle'>
                      <h4>รวมทั้งหมด: {this.getGrandTotal()} บาท</h4>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                      <Button color="blue" size="large" type='submit'>ส่งใบเบิกเงิน</Button>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </CardContent>
            </Card>
          </Form>}

          {this.state.isSubmitted && <Message success header='ส่งใบเบิกเงินเรียบร้อยแล้ว!' content='อยู่ระหว่างการตรวจสอบและอนุมัติ คุณจะได้รับเงินตามรอบวันที่ 5 และ 15 ของทุกเดือน'></Message>}
        </Container>
      </>
    )
  }
}