const banks = [
    {
      "key": "BAAC",
      "text": "ธ.ก.ส.",
      "value": "ธ.ก.ส."
    },
    {
      "key": "BAY",
      "text": "กรุงศรีอยุธยา",
      "value": "กรุงศรีอยุธยา"
    },
    {
      "key": "BBL",
      "text": "กรุงเทพ",
      "value": "กรุงเทพ"
    },
    {
      "key": "CIMB",
      "text": "ซีไอเอ็มบี",
      "value": "ซีไอเอ็มบี"
    },
    {
      "key": "CITI",
      "text": "ซิตี้แบงก์",
      "value": "ซิตี้แบงก์"
    },
    {
      "key": "GHB",
      "text": "ธ.อ.ส.",
      "value": "ธ.อ.ส."
    },
    {
      "key": "GSB",
      "text": "ออมสิน",
      "value": "ออมสิน"
    },
    {
      "key": "HSBC",
      "text": "เอชเอสบีซี",
      "value": "เอชเอสบีซี"
    },
    {
      "key": "IBANK",
      "text": "อิสลามแห่งประเทศไทย",
      "value": "อิสลามแห่งประเทศไทย"
    },
    {
      "key": "ICBC",
      "text": "ไอซีบีซี",
      "value": "ไอซีบีซี"
    },
    {
      "key": "KBANK",
      "text": "กสิกรไทย",
      "value": "กสิกรไทย"
    },
    {
      "key": "KKP",
      "text": "เกียรตินาคิน",
      "value": "เกียรตินาคิน"
    },
    {
      "key": "KTB",
      "text": "กรุงไทย",
      "value": "กรุงไทย"
    },
    {
      "key": "LHB",
      "text": "แลนด์ แอนด์ เฮ้าส์",
      "value": "แลนด์ แอนด์ เฮ้าส์"
    },
    {
      "key": "PromptPay",
      "text": "พร้อมเพย์",
      "value": "พร้อมเพย์"
    },
    {
      "key": "SCB",
      "text": "ไทยพาณิชย์",
      "value": "ไทยพาณิชย์"
    },
    {
      "key": "TCRB",
      "text": "ไทยเครดิต",
      "value": "ไทยเครดิต"
    },
    {
      "key": "TISCO",
      "text": "ทิสโก้",
      "value": "ทิสโก้"
    },
    {
      "key": "TrueMoney",
      "text": "ทรูมันนี่",
      "value": "ทรูมันนี่"
    },
    {
      "key": "TTB",
      "text": "ทีเอ็มบีธนชาต",
      "value": "ทีเอ็มบีธนชาต"
    },
    {
      "key": "UOB",
      "text": "ยูโอบี",
      "value": "ยูโอบี"
    }
  ];

export default banks;